






  import {
    Component, Vue,
  } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { CMS } from '@one/types'
  import GridContainer = CMS.Grid.GridContainer

  const cms = namespace('cms')

  @Component({
    components: {
      OneCmsGridRender: () => import('~/components/molecules/renders/OneCmsGridRender.vue'),
    },
  })
  export default class OneHeaderGridComponent extends Vue {
    availableComponents: any = {
      categoriesDropdown: () => import('~/components/organisms/grid/header/OneCategoriesDropdownComponent.vue'),
      html: () => import('~/components/organisms/grid/shared/OneCmsDynamicComponent.vue'),
      staticHtml: () => import('~/components/organisms/grid/shared/OneCmsStaticComponent.vue'),
      image: () => import('~/components/molecules/images/OneCmsGridImageComponent.vue'),
      cartIcon: () => import('~/components/organisms/grid/header/OneCartIconComponent.vue'),
      wishlistIcon: () => import('~/components/organisms/grid/header/OneWishlistIconComponent.vue'),
      search: () => import('~/components/organisms/grid/header/OneSearchComponent.vue'),
      link: () => import('~/components/molecules/links/OneCmsGridLinkComponent.vue'),
      taxSwitcher: () => import('~/components/organisms/grid/header/OneTaxSwitcherComponent.vue'),
      warehouses: () => import('~/components/organisms/grid/header/OneWarehouseSelectorComponent.vue'),
      accountDropdown: () => import('~/components/organisms/grid/header/OneAccountDropdownComponent.vue'),
      cartValue: () => import('~/components/organisms/grid/header/OneCartValueComponent.vue'),
      loginLogout: () => import('~/components/organisms/grid/header/OneLoginLogoutComponent.vue'),
      managerInfo: () => import('~/components/organisms/grid/header/OneManagerInfoComponent.vue'),
      textEditor: () => import('~/components/organisms/grid/shared/OneCmsTextEditorComponent.vue'),
      codesScanner: () => import('~/components/organisms/shared/OneBarcodeScanner.vue'),
    }

    @cms.Getter configuration: any;

    get header(): GridContainer {
      return this.configuration && this.configuration.header
    }
  }
